import React, { PureComponent } from 'react';
import { Layout, Menu, Icon } from 'antd';
import classnames from 'classnames';
import { connect } from 'react-redux';
import logo from '../../../../assets/logo/logo.png';
import logoCollapsed from '../../../../assets/logo/logo-collapsed.png';
import { collapseMenu, expandMenu } from '../../actions/left-menu';
import { LEFT_MENU } from '../../constants';
import { isLeftMenuCollapsed, getActiveLeftMenuItems } from '../../selectors';
import MenuLink from './MenuLink';
import styles from './LeftMenu.module.scss';
import type { LeftMenuGroup, LeftMenuItem } from '../../types';

type Props = {
  isCollapsed: boolean,
  selectedKeys: string[],
  collapseMenu: () => any,
  expandMenu: () => any,
};

class LeftMenu extends PureComponent<Props> {
  onCollapse = (isCollapsed) => {
    const { collapseMenu, expandMenu } = this.props;
    isCollapsed ? collapseMenu() : expandMenu();
  };

  renderMenuItem = (item: LeftMenuItem) => {
    const { key, url, icon, title, isExternal, target } = item;
    return (
      <Menu.Item key={key || url}>
        <MenuLink to={url} isExternal={isExternal} target={target}>
          <Icon type={icon} />
          <span className={styles.title}>{title}</span>
        </MenuLink>
      </Menu.Item>
    );
  };

  renderMenuGroup = (group: LeftMenuGroup) => {
    const { isCollapsed } = this.props;
    const { groupKey, groupName, shortGroupName, items } = group;
    return (
      <Menu.ItemGroup
        key={groupKey}
        title={isCollapsed ? shortGroupName : groupName}
      >
        {items.map(this.renderMenuItem)}
      </Menu.ItemGroup>
    );
  };

  renderLogo() {
    const image = this.props.isCollapsed ? logoCollapsed : logo;

    return (
      <img
        src={image}
        alt="Q3 Asset Management"
      />
    );
  }

  render() {
    const { isCollapsed, selectedKeys } = this.props;
    return (
      <Layout.Sider
        theme="light"
        collapsible
        collapsed={isCollapsed}
        width={256}
        collapsedWidth={80}
        className={classnames(styles.menu, styles.light)}
        onCollapse={this.onCollapse}
      >
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            {this.renderLogo()}
          </div>
        </div>
        <div>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            className={styles.navigation}
          >
            {LEFT_MENU.map(this.renderMenuGroup)}
          </Menu>
        </div>
      </Layout.Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCollapsed: isLeftMenuCollapsed(state),
    selectedKeys: getActiveLeftMenuItems(state)
  };
};

export default connect(mapStateToProps, {
  collapseMenu,
  expandMenu
})(LeftMenu);
