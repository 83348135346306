import TYPES from '../actions/action-types';
import type { TfaEnableDialogState } from '../types';

const initialState: TfaEnableDialogState = {
  isOpened: false,
  isReady: false
};

// todo: replace with createReducer() call
export default (state = initialState, action): TfaEnableDialogState => {
  switch (action.type) {
    case TYPES.ENABLE_DIALOG.OPENED:
      return {
        ...state,
        isOpened: true
      };
    case TYPES.ENABLE_DIALOG.GOT_READY:
      if (!state.isOpened) {
        return state;
      }
      return {
        ...state,
        isReady: true
      };
    case TYPES.ENABLE_DIALOG.CLOSED:
      return initialState;
    default:
      return state;
  }
};
