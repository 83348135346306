import { Map as ImmutableMap } from 'immutable';
import createReducer from '../../common/utils/create-reducer';
import TYPES from '../actions/action-types';
import { DEFAULT_CONTAINER_STATE } from '../constants';
import type { AllContainersState } from '../types';

const INITIAL_STATE = ImmutableMap();
const actionHandlers = new Map();

actionHandlers.set(TYPES.INIT_START, (state: AllContainersState, action) => {
  const containerId = action.payload;
  return state.set(containerId, { ...DEFAULT_CONTAINER_STATE, isBeingInitialized: true });
});

actionHandlers.set(TYPES.INIT_SUCCESS, (state: AllContainersState, action) => {
  const containerId = action.payload;
  return state.set(containerId, { ...DEFAULT_CONTAINER_STATE, isReady: true });
});

actionHandlers.set(TYPES.INIT_FAILURE, (state: AllContainersState, action) => {
  const { containerId, error } = action.payload;
  return state.set(containerId, { ...DEFAULT_CONTAINER_STATE, error });
});

actionHandlers.set(TYPES.RESET, (state: AllContainersState, action) => {
  const containerId = action.payload;
  return state.delete(containerId);
});

export default createReducer(INITIAL_STATE, actionHandlers);
