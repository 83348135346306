import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultLayout from '../../default/components/DefaultLayout';

export default function PublicLayout(props) {
  return (
    <>
      <Helmet title="Advisor Tools" />
      <DefaultLayout {...props} />
    </>
  );
}