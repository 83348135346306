import { createSelector } from 'reselect';
import URLS from '../../urls';
import LAYOUT_NAMES from '../constants/layouts';
import { getPathname } from './url';

export default createSelector([getPathname], (pathname) => {
  if (pathname.startsWith(URLS.authSection)) {
    return LAYOUT_NAMES.login;
  }

  if (pathname.startsWith(URLS.publicSection)) {
    return LAYOUT_NAMES.public;
  }

  return LAYOUT_NAMES.dashboard;
});
