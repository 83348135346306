import React from 'react';
import { Layout } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import classnames from 'classnames';
import logo from '../../../assets/logo/logo-white.png';
import { LOGIN } from '../../../auth/routes/urls';
import urls from '../../../urls';
import styles from './LoginLayout.module.scss';

export default function LoginLayout(props) {
  const { children } = props;
  return (
    <Layout>
      <Layout.Content>
        <div className={styles.layout}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <Link to={urls.index}>
                <img
                  src={logo}
                  alt="Q3 Asset Management"
                />
              </Link>
            </div>

            <nav className={styles.navigation}>
              <ul className={styles.navigationItems}>
                <li>
                  <NavLink
                    to={LOGIN}
                    activeClassName={styles.navigationActive}
                  >
                    Login
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          <div className={styles.content}>{children}</div>

          <div className={classnames(styles.footer, 'text-center')}>
            <p>© 2019 Q3 Asset Management. All rights reserved.</p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}