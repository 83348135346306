// @flow
import ApiConnector from './ApiConnector';
import ApiTokenProvider from './ApiTokenProvider';
import type { ReduxStore } from '../types/redux';

let connector;

export const createApiConnector = (store: ReduxStore): void => {
  if (!connector) {
    const tokenProvider = new ApiTokenProvider(store);
    connector = new ApiConnector(tokenProvider);
  }
};

export const getApiConnector = (): ApiConnector => {
  if (!connector) {
    throw new Error('API Connector is not created yet');
  }

  return connector;
};
