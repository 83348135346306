import TYPES from './action-types';

export const collapseMenu = () => {
  return {
    type: TYPES.LEFT_MENU.COLLAPSED
  };
};

export const expandMenu = () => {
  return {
    type: TYPES.LEFT_MENU.EXPANDED
  };
};
