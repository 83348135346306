// @flow
import { getApiConnector } from '../../main/api';

type TwoFactorAuthStatusResponse = {
  tfa_enabled: boolean
}

type AuthenticatorConfigResponse = {
  url: string
}

class UserApi {
  get apiConnector() {
    return getApiConnector();
  }

  getUserDetails(): Promise {
    return this.apiConnector.doRequest(
      'GET',
      'profile/',
      true
    );
  }

  async checkTwoFactorAuthStatus(): Promise<boolean> {
    const response: TwoFactorAuthStatusResponse =
      await this.apiConnector.doRequest(
        'GET',
        'otp/totp/',
        true
      );

    return response.tfa_enabled;
  }

  async getAuthenticatorConfigUrl(): Promise<string> {
    const response: AuthenticatorConfigResponse =
      await this.apiConnector.doRequest(
        'POST',
        'otp/totp/',
        true
      );

    return response.url;
  }

  confirmUsingTwoFactorAuth(token): Promise<any> {
    return this.apiConnector.doRequest(
      'POST',
      `otp/totp/confirm/${token}/`,
      true
    );
  }

  disableTwoFactorAuth(): Promise<any> {
    return this.apiConnector.doRequest(
      'DELETE',
      'otp/totp/',
      true
    );
  }
}

export default UserApi;
