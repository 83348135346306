import { getApiToken } from '../../auth/session/selectors';
import type { ReduxStore } from '../types/redux';

const HEADER_PREFIX = 'Token';

class ApiTokenProvider {
  /**
   * @private
   * @type {ReduxStore}
   */
  store: ReduxStore = null;

  constructor(store: ReduxStore) {
    /**
     * @private
     * @type {ReduxStore}
     */
    this.store = store;
  }

  /**
   * Returns current api token
   * @return {string}
   */
  getApiToken(): string {
    return [HEADER_PREFIX, getApiToken(this.store.getState())].join(' ');
  }
}

export default ApiTokenProvider;
