// @flow
import axios from 'axios';

const AUTH_HEADER_NAME = 'Authorization';

class ApiConnector {
  /**
   * @private
   * @type {AxiosInstance}
   */
  axios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
  });

  /**
   * @private
   * @type {ApiTokenProvider}
   */
  tokenProvider = null;

  /**
   *
   * @param {ApiTokenProvider} tokenProvider
   */
  constructor(tokenProvider) {
    this.tokenProvider = tokenProvider;
  }

  /**
   *
   * @param {string} method
   * @param {string} url
   * @param {boolean} [isAuthRequired]
   * @param {any} [data]
   * @param {Object} [headers]
   * @param {Object} [options]
   * @return {Promise<any>}
   */
  async doRequest(method, url, isAuthRequired, data, headers, options) {
    method = method.toUpperCase();
    const dataProperty = method === 'GET' ? 'params' : 'data';

    headers = {
      'Content-Type': 'application/json',
      ...headers
    };
    if (isAuthRequired) {
      headers[AUTH_HEADER_NAME] = this.tokenProvider.getApiToken();
    }

    const response = await this.axios.request({
      ...options,
      method,
      url,
      headers,
      [dataProperty]: data
    });

    return response.data;
  }
}

export default ApiConnector;
