import React from 'react';
import { Layout } from 'antd';
import classnames from 'classnames';
import Breadcrumbs from './Breadcrumbs';
import TopBar from './top-bar';
import Footer from './footer';
import { LeftMenu } from './menu';
import styles from './DefaultLayout.module.scss';

export default function DefaultLayout(props) {
  const { children } = props;
  return (
    <Layout className={classnames(styles.layout, "settings__borderLess")}>
      <LeftMenu />
      <Layout>
        <Layout.Header>
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <Breadcrumbs />
          <div className="utils__content">{children}</div>
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}
