import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  children: React.ReactNode,
  to: string,
  isExternal: boolean
};

class MenuLink extends PureComponent<Props> {
  static defaultProps = {
    isExternal: false
  };

  render() {
    const { children, to, isExternal, ...linkProps } = this.props;
    let LinkComponent = NavLink;

    if (isExternal) {
      LinkComponent = 'a';
      linkProps.href = to;
      if (!linkProps.target) {
        linkProps.target = '_blank';
      }
    } else {
      linkProps.to = to;
    }

    return React.createElement(LinkComponent, linkProps, children);
  }

}

export default MenuLink;
