import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import { createApiConnector } from './main/api';
import App from './app/components/App';
import Router from './router';
import './index.scss';

const history = createBrowserHistory();
const { store, persistor } = configureStore({}, history);
createApiConnector(store);

ReactDOM.render(
  <StoreProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App>
          <Router />
        </App>
      </ConnectedRouter>
    </PersistGate>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
