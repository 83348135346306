import TYPES from '../constants/action-types';

const initialState = {
  isReady: false
};

export default (state = initialState, { type }) => {
  switch (type) {
    case TYPES.INIT.START:
      return initialState;
    // no re-init procedure exists for a while
    // so just update readiness flag to let the app render login screen
    case TYPES.INIT.SUCCESS:
    case TYPES.INIT.FAILURE:
      return { ...state, isReady: true };
    default:
      return state;
  }
};
