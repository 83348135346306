import TYPES from '../actions/action-types';
import type { PasswordResetState } from '../types';

const initialState: PasswordResetState = {
  uid: '',
  token: ''
};

export default (state = initialState, { type, payload }): PasswordResetState => {
  switch (type) {
    case TYPES.INIT:
      return { ...state, ...payload };
    case TYPES.COMPLETE:
      return initialState;
    default:
      return state;
  }
};
