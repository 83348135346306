import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserDetails } from '../../user/details/actions';
import { LOGIN } from '../../auth/routes/urls';
import { isSessionOpened } from '../../auth/session/selectors';

type Props = {
  children?: React.Node,
  isUserLoggedIn: boolean,
  fetchUserDetails: () => Promise,
};

class ProtectedArea extends PureComponent<Props> {

  componentDidMount() {
    // todo:
    //  1. find a better place for fetching user details
    //  2. control fetching process, react on errors
    const { isUserLoggedIn, fetchUserDetails } = this.props;
    if (isUserLoggedIn) {
      fetchUserDetails();
    }
  }

  render() {
    const { isUserLoggedIn, children } = this.props;
    if (!isUserLoggedIn) {
      return <Redirect to={LOGIN} />
    }

    return children || null;
  }
}


const mapStateToProps = (state) => ({
  isUserLoggedIn: isSessionOpened(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUserDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedArea)



