export default {
  index: '/',
  authSection: '/auth',
  twoFactorAuth: '/profile/two-factor-auth',
  dashboard: '/dashboard',
  publicSection: '/public',

  external: {
    // actually, these env variables should be available always,
    // otherwise routing won't work correctly;
    // but this default value is added to avoid runtime errors relates to operations under strings
    proposalWizard: process.env.REACT_APP_PROPOSAL_WIZARD_URL || '/',
    portfolioStressTest: process.env.REACT_APP_PORTFOLIO_STRESS_TEST_URL || '/',
    q3Site: 'https://www.q3tactical.com/',
    q3StrategyEngine: 'https://www.q3tactical.com/Q3reports/reports/strategyEngine.html'
  }
};
