import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Menu, Dropdown, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { logout } from '../../../../../auth/logout/actions';
import { getUserDetailsState } from '../../../../../user/details/selectors';
import urls from '../../../../../urls';
import styles from './ProfileMenu.module.scss';

type Props = {
  logout: () => any
};

class ProfileMenu extends React.Component<Props> {
  logout = () => {
    this.props.logout();
  };

  render() {
    const { user } = this.props;
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            Hello, {user.username}
          </strong>
          <div>
            <strong>
              Email:{' '}
            </strong>
            {user.email || '–'}
            <br />
            <strong>
              Phone:{' '}
            </strong>
            {user.phone || '-'}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to={urls.twoFactorAuth}>
            <i className={classnames("fas", "fa-key", styles.menuIcon)} />
            Two-Factor Auth
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.logout}>
          <span>
            <i className={classnames("fas", "fa-sign-out-alt", styles.menuIcon)} />
            Log out
          </span>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
        </div>
      </Dropdown>
    )
  }
}


const mapStateToProps = (state) => ({
  user: getUserDetailsState(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
