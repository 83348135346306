import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className="row">
        <div className="col-sm-12">
          <div className={styles.copyright}>
            <span>
              © 2019{' '}
              <a href="https://www.q3tactical.com/" target="_blank" rel="noopener noreferrer">
                Q3 Asset Management
              </a>
              <br />
              All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
