import GLOBAL_URLS from '../../../urls';

export const LEFT_MENU = [
  {
    groupName: 'Advisor Tools',
    shortGroupName: 'Advisor Tools',
    groupKey: 'advisor-tools',
    items: [
      {
        key: 'proposal-wizard',
        url: GLOBAL_URLS.external.proposalWizard,
        icon: 'file-done',
        title: 'Proposal Wizard',
        isExternal: true,
        target: '_self'
      },
      {
        key: 'portfolio-stress-test',
        url: GLOBAL_URLS.external.portfolioStressTest,
        icon: 'sliders',
        title: 'Portfolio Stress Test',
        isExternal: true,
        target: '_self'
      }
    ]
  },
  {
    groupName: 'External Links',
    shortGroupName: 'External',
    groupKey: 'external-links',
    items: [
      {
        key: 'q3-site',
        url: GLOBAL_URLS.external.q3Site,
        icon: 'global',
        title: 'Q3AM Website',
        isExternal: true
      },
      {
        key: 'q3-strategy-engine',
        url: GLOBAL_URLS.external.q3StrategyEngine,
        icon: 'global',
        title: 'Q3AM Strategy Engine',
        isExternal: true
      }
    ]
  }
];
