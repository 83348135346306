// @flow
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import getLayoutName from '../common/selectors/getLayoutName';
import LAYOUT_NAMES from '../common/constants/layouts';
import { LoginLayout } from './login/components';
import PublicLayout from './public/components/PublicLayout';
import DashboardLayout from './dashboard/components/DashboardLayout';

const Layouts = {
  [LAYOUT_NAMES.login]: LoginLayout,
  [LAYOUT_NAMES.public]: PublicLayout,
  [LAYOUT_NAMES.dashboard]: DashboardLayout
};

type Props = {
  layoutName: string,
  children?: React.Node
};

class IndexLayout extends PureComponent<Props> {
  renderContent() {
    const { layoutName, children } = this.props;
    const Container = Layouts[layoutName];
    return (
      <Container>
        {children}
      </Container>
    );
  }

  render() {
    const internalContent = this.renderContent();
    return (
      <Fragment>
        <Helmet titleTemplate="Advisor Web Portal | %s" title="Main" />
        {internalContent}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    layoutName: getLayoutName(state)
  };
};

export default connect(mapStateToProps)(IndexLayout);
