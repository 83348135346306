import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import URLS from '../../../urls';

const DefaultBreadcrumbs = () => null;

const RouteSpecificBreadcrumbs = () => null;

export default class Breadcrumbs extends PureComponent {
  render() {
    // every breadcrumbs component is responsible for creating its own item list
    // there is no "standard" format to describe all urls in the app
    return (
      <Switch>
        <Route exact path={URLS.index} component={RouteSpecificBreadcrumbs} />
        <Route component={DefaultBreadcrumbs} />
      </Switch>
    );
  }
}
