import type { RouteComponentProps } from 'react-router';

export const getRouterState = (state) => state.router;

export const getLocation = (state) => getRouterState(state).location;

export const getPathname = (state) => getLocation(state).pathname;

export const getRouteParams = (state, props: RouteComponentProps) => {
  // avoid accidental usage of selector in not connected to router components
  if (!props) {
    return {};
  }

  return props.match.params;
};
