// @flow
import { getApiConnector } from '../../main/api';

export type MicrosoftOAuthDataResponse = {
  microsoft_login_enabled: boolean,
  microsoft_authorization_url: string,
  microsoft_login_type_text: string
};

export type SuccessfulLoginResponse = {
  token: string
}

export type OtpRequiredResponse = {
  otp_required: boolean,
  auth_token: string
};

export type LoginResponse = SuccessfulLoginResponse | OtpRequiredResponse;

class AuthApi {

  get apiConnector() {
    return getApiConnector();
  }

  /**
   * Takes username and password
   * and returns api token for user with given credentials
   *
   * @param {string} username
   * @param {string} password
   * @return {Promise<string>}
   */
  async login(username: string, password: string):
    Promise<LoginResponse> {
    return this.apiConnector.doRequest(
      'POST',
      'auth/login/',
      false,
      { username, password }
    );
  }

  async sendOneTimePassword(token: string, otp: string):
    Promise<SuccessfulLoginResponse> {
    return this.apiConnector.doRequest(
      'POST',
      'auth/login/confirm/',
      false,
      { token, otp }
    );
  }

  /**
   * Sends a request for closing current user session
   *
   * @return {Promise<void>}
   */
  async logout(): Promise {
    await this.apiConnector.doRequest('POST', 'auth/logout/', true);
  }

  /**
   * Sends a request for sending email with password reset instructions
   *
   * @param {string} email
   * @return {Promise<void>}
   */
  async requestPasswordResetEmail(email: string): Promise {
    await this.apiConnector.doRequest(
      'POST',
      'auth/password/reset/',
      false,
      { email }
    );
  }

  /**
   * Sends a request for changing forgotten password
   *
   * @param {string} uid
   * @param {string} token
   * @param {string} password
   * @return {Promise<void>}
   */
  async confirmPasswordReset(uid: string, token: string, password: string): Promise {
    await this.apiConnector.doRequest(
      'POST',
      'auth/password/reset/confirm/',
      false,
      {
        uid,
        token,
        new_password1: password,
        new_password2: password
      }
    );
  }

  /**
   * Returns settings of Active Directory login process
   *
   * @return {Promise<any>}
   */
  async getMicrosoftOAuthSettings(): Promise {
    const response: MicrosoftOAuthDataResponse = await this.apiConnector.doRequest(
      'GET',
      'auth/oauth/microsoft/',
      false
    );

    return {
      isEnabled: response.microsoft_login_enabled,
      authUrl: response.microsoft_authorization_url
    };
  }
}

export default AuthApi;
