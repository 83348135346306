import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import localforage from 'localforage';

import createRootReducer from '../main/reducers';

const persistConfig = {
  key: 'q3_web_portal',
  storage: localforage.createInstance({
    name: 'Q3 Client Portal',
    storeName: 'q3_web_portal'
  }),
  whitelist: ['auth', 'layouts'],
  transforms: [
    createWhitelistFilter('auth', ['session'])
  ]
};

export default function configureStore(initialState, history) {
  // Router reducer requires history object
  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middleware = [thunk];
  const enhancers = [];
  // Avoid logs in production builds and during tests running
  if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger({
      level: 'info',
      collapsed: true
    }));
  }

  middleware.push(routerMiddleware(history));

  enhancers.push(applyMiddleware(...middleware));
  const enhancer = compose(...enhancers);

  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);

  return { store, persistor };
};
