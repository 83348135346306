import { combineReducers } from 'redux';
import session from '../../session/reducers';
import reset from '../../reset/reducers';
import oauth from '../../oauth/main/reducers';
import otp from '../../otp/reducers';

export default combineReducers({
  session,
  reset,
  oauth,
  otp
});
