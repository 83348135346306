import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import auth from '../../auth/main/reducers';
import app from '../../app/reducers';
import user from '../../user/main/reducers';
import containers from '../../containers/reducers';
import modals from '../../modals/reducers';
import layouts from '../../layouts/main/reducers';

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth,
    app,
    user,
    containers,
    modals,
    layouts
  });
}
