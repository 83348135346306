export default {
  SETTINGS: {
    LOADING: 'AUTH/OAUTH/MICROSOFT/SETTINGS/LOADING',
    LOAD_SUCCESS: 'AUTH/OAUTH/MICROSOFT/SETTINGS/LOAD_SUCCESS',
    LOAD_FAILURE: 'AUTH/OAUTH/MICROSOFT/SETTINGS/LOAD_FAILURE',
  },
  AUTH_PROCESS: {
    START: 'AUTH/OAUTH/MICROSOFT/AUTH_PROCESS/START',
    SUCCESS: 'AUTH/OAUTH/MICROSOFT/AUTH_PROCESS/SUCCESS',
    FAILURE: 'AUTH/OAUTH/MICROSOFT/AUTH_PROCESS/FAILURE',
  }
}
