import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isSessionOpened } from '../../auth/session/selectors';
import urls from '../../urls';

type Props = {
  isUserLoggedIn: boolean
};

class IndexPage extends PureComponent<Props> {
  render() {
    const to = this.props.isUserLoggedIn ? urls.dashboard : urls.publicSection;
    return <Redirect to={to} />;
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: isSessionOpened(state)
});

export default connect(mapStateToProps)(IndexPage)
