import { getUserApi } from '../../api';
import TYPES from './action-types';

export const updateUserDetails = (userDetails) => {
  return {
    type: TYPES.UPDATE,
    payload: userDetails
  };
};

export const fetchUserDetails = () =>
  async (dispatch) => {
    try {
      const userDetails = await getUserApi().getUserDetails();
      dispatch(updateUserDetails(userDetails));
    } catch (error) {
      // todo: handle error, not just log it
      console.error('User details fetching failed', error);
    }
  };
