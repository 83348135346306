import createReducer from '../../../common/utils/create-reducer';
import TYPES from '../actions/action-types';
import type { LeftMenuState } from '../types';

const INITIAL_STATE: LeftMenuState = {
  isCollapsed: false
};
const actionHandlers = new Map();

actionHandlers.set(TYPES.LEFT_MENU.COLLAPSED, (state: LeftMenuState) => {
  return { ...state, isCollapsed: true };
});

actionHandlers.set(TYPES.LEFT_MENU.EXPANDED, (state: LeftMenuState) => {
  return { ...state, isCollapsed: false };
});

export default createReducer(INITIAL_STATE, actionHandlers);
