// @flow
import { createSelector } from 'reselect';
import { getAuthState } from '../../main/selectors';
import type { GlobalState } from '../../../main/types/redux';
import type { SessionState } from '../types';

export const getSessionState = (state: GlobalState): SessionState =>
  getAuthState(state).session;

export const getApiToken = (state: GlobalState): string =>
  getSessionState(state).apiToken;

export const isSessionOpened = createSelector(
  [getApiToken],
  (token: string): boolean => !!token
);
