import TYPES from '../actions/action-types';
import type { SessionState } from '../types';

const initialState: SessionState = {
  apiToken: ''
};

export default (state = initialState, { type, payload }): SessionState => {
  switch (type) {
    case TYPES.START:
      return { ...state, apiToken: payload };
    case TYPES.CLOSE:
      return initialState;
    default:
      return state;
  }
};
