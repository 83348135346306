import TYPES from '../actions/action-types';
import type { TwoFactorAuthStatusState } from '../types';

const initialState: TwoFactorAuthStatusState = {
  isEnabled: false
};

// todo: replace with createReducer() call
export default (state = initialState, { type, payload }): TwoFactorAuthStatusState => {
  switch (type) {
    case TYPES.STATUS.UPDATE:
      return {
        ...state,
        isEnabled: payload
      };
    default:
      return state;
  }
};
