import { createSelector } from 'reselect';
import { getPathname } from '../../../common/selectors/url';
import { ensureTrailingSlash } from '../../../common/utils/slashes';
import { LEFT_MENU } from '../constants';
import { getDefaultLayoutState } from './base';

import type { GlobalState } from '../../../main/types/redux';
import type { LeftMenuState, LeftMenuItem } from '../types';

const LEFT_MENU_ITEMS: LeftMenuItem[] = LEFT_MENU.reduce((items, group) => {
  return items.concat(group.items);
}, []);

export const getLeftMenuState = (state: GlobalState): LeftMenuState => getDefaultLayoutState(state).leftMenu;

export const isLeftMenuCollapsed = createSelector(
  [getLeftMenuState],
  (menuState) => {
    return menuState.isCollapsed;
  }
);

export const getActiveLeftMenuItems = createSelector(
  [getPathname],
  (pathname) => {
    pathname = ensureTrailingSlash(pathname);
    return  LEFT_MENU_ITEMS
      .filter((it) => {
        return pathname.startsWith(ensureTrailingSlash(it.url))
      })
      .map(it => it.key);
  }
);
