// @flow
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../common/components/loader';
import getAppReadiness from '../selectors/getAppReadiness';
import { init } from '../actions/init';

type Props = {
  children?: React.node,
  isReady: boolean,
  init: () => Promise<any>
};

class App extends PureComponent<Props> {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const { isReady, children } = this.props;
    if (isReady) {
      return children;
    }

    return <Loader />;
  }
}

const mapStateToProps = (state) => ({
  isReady: getAppReadiness(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  init
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
