export const ensureLeadingSlash = (path: string, needsSlash = false): string => {
  const hasSlash = path.startsWith('/');
  if (hasSlash && !needsSlash) {
    return path.slice(1);
  } else if (!hasSlash && needsSlash) {
    return '/' + path;
  } else {
    return path;
  }
};

export const ensureTrailingSlash = (path: string, needsSlash = true): string => {
  const hasSlash = path.endsWith('/');
  if (hasSlash && !needsSlash) {
    return path.slice(0, -1);
  } else if (!hasSlash && needsSlash) {
    return path + '/';
  } else {
    return path;
  }
};
