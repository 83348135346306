import TYPES from '../actions/action-types';
import type { OtpState } from '../types';

const initialState: OtpState = {
  isRequired: false,
  authToken: ''
};

export default (state = initialState, { type, payload }): OtpState => {
  switch (type) {
    case TYPES.REQUEST_RECEIVED:
      return {
        ...state,
        isRequired: true,
        authToken: payload
      };
    case TYPES.COMPLETED:
      return initialState;
    default:
      return state;
  }
};
