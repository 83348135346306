export default {
  PAGE: {
    INITIALIZED: 'USER/2FA/PAGE/INITIALIZED',
    RESET: 'USER/2FA/PAGE/RESET',
  },
  STATUS: {
    UPDATE: 'USER/2FA/STATUS/UPDATE'
  },
  DEVICE: {
    CONFIG_RECEIVED: 'USER/2FA/DEVICE/CONFIG_RECEIVED'
  },
  ENABLE_DIALOG: {
    OPENED: 'USER/2FA/ENABLE_DIALOG/OPENED',
    GOT_READY: 'USER/2FA/ENABLE_DIALOG/GOT_READY',
    CLOSED: 'USER/2FA/ENABLE_DIALOG/CLOSED',
  }
};
