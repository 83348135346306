import TYPES from '../actions/action-types';
import type { TfaPageState } from '../types';

const initialState: TfaPageState = {
  isReady: false
};

// todo: replace with createReducer() call
export default (state = initialState, { type }): TfaPageState => {
  switch (type) {
    case TYPES.PAGE.INITIALIZED:
      return {
        ...state,
        isReady: true
      };
    case TYPES.PAGE.RESET:
      return initialState;
    default:
      return state;
  }
};
