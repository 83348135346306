import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import urls from './urls';
import Loader from './common/components/loader';
import IndexLayout from './layouts';
import IndexPage from './main/components/IndexPage';
import ProtectedArea from './common/components/ProtectedArea';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

class Router extends React.Component {
  render() {
    return (
      <IndexLayout>
        <Switch>
          <Route exact path={urls.index} component={IndexPage} />

          <Route
            path={urls.authSection}
            component={loadable(() => import('./auth/routes/router'))}
          />

          <Route
            path={urls.publicSection}
            component={loadable(() => import('./public/routes/router'))}
          />

          <ProtectedArea>
            <Switch>
              <Route
                path={urls.twoFactorAuth}
                component={loadable(() => import('./user/2fa/components/TwoFactorAuthSettingsPage'))}
              />
              <Route
                path={urls.dashboard}
                component={loadable(() => import('./dashboard/routes/router'))}
              />
            </Switch>
          </ProtectedArea>

          <Route component={() => <div>Not found</div>} />
        </Switch>
      </IndexLayout>
    )
  }
}

export default Router
