import TYPES from '../actions/action-types';
import type { UserDetailsState } from '../types';

const initialState: UserDetailsState = {
  id: '',
  username: '',
  email: '',
  first_name: '',
  last_name: ''
};

// todo: replace with createReducer() call
export default (state = initialState, { type, payload }): UserDetailsState => {
  switch (type) {
    case TYPES.UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};
