import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isSessionOpened } from '../../../../auth/session/selectors';
import ProfileMenu from './profile-menu';
import styles from './TopBar.module.scss';

type Props = {
  isUserLoggedIn: boolean
};

class TopBar extends React.Component<Props> {
  renderProfileMenu() {
    if (!this.props.isUserLoggedIn) {
      return null;
    }

    return (
      <ProfileMenu />
    );
  }

  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-4">
          <h3>
            <Link to="/">
              Advisor Web Portal
            </Link>
          </h3>
        </div>
        <div className="mr-auto" />
        {this.renderProfileMenu()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: isSessionOpened(state)
});

export default connect(mapStateToProps)(TopBar)
