import TYPES from '../actions/action-types';
import type { TotpDeviceState } from '../types';

const initialState: TotpDeviceState = {
  configUrl: ''
};

// todo: replace with createReducer() call
export default (state = initialState, { type, payload }): TotpDeviceState => {
  switch (type) {
    case TYPES.DEVICE.CONFIG_RECEIVED:
      return {
        ...state,
        configUrl: payload
      };
    default:
      return state;
  }
};
