import TYPES from '../actions/action-types';
import type { MicrosoftOAuthStatus } from '../types';

const initialState: MicrosoftOAuthStatus = {
  inProgress: false
};

export default (state = initialState, { type }): MicrosoftOAuthStatus => {
  switch (type) {
    case TYPES.AUTH_PROCESS.START:
      return { ...state, inProgress: true };
    case TYPES.AUTH_PROCESS.SUCCESS:
    case TYPES.AUTH_PROCESS.FAILURE:
      return initialState;
    default:
      return state;
  }
};
