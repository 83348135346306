import { Map as ImmutableMap } from 'immutable';
import createReducer from '../../common/utils/create-reducer';
import TYPES from '../actions/action-types';
import { DEFAULT_MODAL_STATE } from '../constants';
import type { AllModalsState } from '../types';

const INITIAL_STATE = ImmutableMap();
const actionHandlers = new Map();

actionHandlers.set(TYPES.REGISTER, (state: AllModalsState, action) => {
  const modalId = action.payload;
  return state.set(modalId, DEFAULT_MODAL_STATE);
});

actionHandlers.set(TYPES.OPEN, (state: AllModalsState, action) => {
  const { modalId, context } = action.payload;
  if (!state.has(modalId)) {
    return state;
  }
  return state.update(modalId, (modalState) => ({
    ...modalState,
    isOpen: true,
    context
  }))
});

actionHandlers.set(TYPES.CLOSE, (state: AllModalsState, action) => {
  const modalId = action.payload;
  return state.set(modalId, DEFAULT_MODAL_STATE);
});

actionHandlers.set(TYPES.DESTROY, (state: AllModalsState, action) => {
  const modalId = action.payload;
  return state.delete(modalId);
});


export default createReducer(INITIAL_STATE, actionHandlers);
