import cookie from 'js-cookie';
import TYPES from './action-types';

const EXTERNAL_SERVICE_TOKEN_COOKIE = "AUTHTOKEN";

export const startSession = (apiToken: string) => (dispatch) => {
  // this cookie will be used for access to external services
  // "expires" option is required, without it the cookie will be deleted after closing browser
  cookie.set(EXTERNAL_SERVICE_TOKEN_COOKIE, apiToken, { expires: 20 * 365 });
  // and the same value will be used for communicating with API
  dispatch({
    type: TYPES.START,
    payload: apiToken
  });
};

export const closeSession = () => (dispatch) => {
  cookie.remove(EXTERNAL_SERVICE_TOKEN_COOKIE);
  dispatch({
    type: TYPES.CLOSE
  });
};
