import { push } from 'connected-react-router';
import { getAuthApi } from '../../api';
import { closeSession } from '../../session/actions';

export const logout = () =>
  async (dispatch) => {
    // no need to wait a request completion
    getAuthApi().logout().catch((error) => {
      // todo: send error logs to some analytic system
      console.error('Cannot revoke api token', error)
    });

    dispatch(closeSession());
    dispatch(push('/'));
  };
