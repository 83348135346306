import TYPES from '../actions/action-types';
import type { MicrosoftOAuthSettings } from '../types';

const initialState: MicrosoftOAuthSettings = {
  isEnabled: false,
  authUrl: ''
};

export default (state = initialState, { type, payload }): MicrosoftOAuthSettings => {
  switch (type) {
    case TYPES.SETTINGS.LOADING:
      return initialState;
    case TYPES.SETTINGS.LOAD_SUCCESS:
      return { ...state, ...payload };
    case TYPES.SETTINGS.LOAD_FAILURE:
      return initialState;
    default:
      return state;
  }
};
