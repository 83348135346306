import { combineReducers } from 'redux';
import status from './status';
import device from './device';
import enableDialog from './enable-dialog';
import page from './page';

export default combineReducers({
  status,
  device,
  enableDialog,
  page
});
