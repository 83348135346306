import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultLayout from '../../default/components/DefaultLayout';

export default function DashboardLayout(props) {
  return (
    <>
      <Helmet title="Dashboard" />
      <DefaultLayout {...props} />
    </>
  );
}